<template>
  <a-space direction="vertical" class="cardItem">
    <div style="display: flex; flex-wrap: wrap;gap: 10px;" v-if="!store.state.auth.mainPageInfo?.hasActivePlan">
      <PlanCard @click="$router.push({ name: 'plans' })" />
    </div>
    <div v-else style="display: flex; flex-wrap: wrap;gap: 10px;">
      <UpcomingCard :display-modal="upcomingModal" @open="openUpcomingModal"
        @click="$router.push({ name: 'upcomingTasks' })" />
      <NewClientCard @click="$router.push('/new-client')" />
      <ClientCard @click="$router.push('/client-search')" />
    </div>
  </a-space>
</template>
<script setup>
import { onMounted, ref } from 'vue';
import PlanCard from '@/components/pages/homePageCards/PlanCard.vue';
import UpcomingCard from '@/components/pages/homePageCards/UpcomingCard.vue';
//import CompanyCard from '@/components/pages/homePageCards/CompanyCard.vue';
import ClientCard from '@/components/pages/homePageCards/ClientCard.vue';
import NewClientCard from '@/components/pages/homePageCards/NewClientCard.vue';
import { notification } from 'ant-design-vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

const router = useRouter();
const store = useStore();

onMounted(() => {
  if (!store.state.auth.mainPageInfo?.hasActivePlan && store.state.auth.basketCount == 0) {
    openNotification(`İşlem yapabilmek için paket satın almanız gerekmektedir. Teklifleri incelemek için lütfen tıklayınız.`, '/plans')
  }
  if (store.state.auth.mainPageInfo?.packageAlert.length > 0) {
    openNotification(store.state.auth.mainPageInfo?.packageAlert[0], '/plans')
  }
});

const key = `open${Date.now()}`;
const openNotification = (description, url) => {
  notification.open({
    message: ``,
    description: description,
    placement: 'top',
    onClick: () => {
      router.push(url);
      notification.close(key);
    },
    key,
    duration: 4,
    maxCount: 1,
  });
};

const upcomingModal = ref(false)
const openUpcomingModal = () => {
  upcomingModal.value = !upcomingModal.value
}

</script>

<style scoped>
.cardItem {
  margin-top: 10px;
  text-align: center
}
</style>