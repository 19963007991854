import { performRequest } from '@/services/axios.interceptor'

class UserService {
    getUserInfo() {
       return performRequest('get', '/get-user')
    }

    getMainPageInfo() {
        return performRequest('get', '/get-main-page-info')
    }
    
    updateUserInfo(userInfo) {
        return performRequest('post', '/update/user', userInfo)
    }

    resetPassword(passwords) {
        return performRequest('post', '/user/pass-change-from-reset-code', passwords)
    }
}

export default new UserService();