<template>
    <div class="bottom-menu" v-if="store.state.auth.status.loggedIn">
        <div class="menu-item" @click="$router.push('/')">
            <HomeOutlined :style="{fontSize: '20px'}" />
            <p>Anasayfa</p>
        </div>
        <div class="menu-item" @click="$router.push('/client-search')">
            <SearchOutlined :style="{fontSize: '20px'}"/>
            <p>Müşteri Arama</p>
        </div>
        <div class="menu-item" @click="$router.push('/new-client')">
            <UserAddOutlined :style="{fontSize: '20px'}" />
            <p>Müşteri Ekle</p>
        </div>
        <div class="menu-item" @click="$router.push('/upcoming-tasks')">
            <CalendarOutlined :style="{fontSize: '20px'}" />
            <p>Takvimim</p>
        </div>
        <div class="menu-item" @click="$router.push('/new-appointment')">
            <PlusSquareOutlined :style="{fontSize: '20px'}" />
            <p>Randevu Oluştur</p>
        </div>
    </div>
</template>
<script setup>
import { useStore } from 'vuex';
import { HomeOutlined, SearchOutlined, CalendarOutlined, UserAddOutlined, PlusSquareOutlined } from '@ant-design/icons-vue';
const store = useStore()


</script>
<style>
.bottom-menu {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 7%;
    background-color: #001529;
    color: white;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px 0;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.3);
    z-index: 1000;
    min-height : fit-content
}

.bottom-menu .menu-item {
    text-align: center;
    flex: 1;
    padding: 5px;
}

.bottom-menu .menu-item i {
    font-size: 24px;
    margin-bottom: 5px;
}

.bottom-menu .menu-item p {
    user-select: none;
    font-size: 11px;
    margin: auto;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    text-wrap: nowrap;
}
</style>