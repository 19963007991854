import axios from 'axios';
import store from '../store';
import router from '@/router';

const apiClient = axios.create({
    baseURL: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_API_URL_PROD :
        (process.env.NODE_ENV === 'development' ? process.env.VUE_APP_API_URL_TEST : process.env.VUE_APP_API_URL_LOCAL),
        
    headers: {
        "Content-Type": "application/json",
    },
});

const setupInterceptors = () => {
    apiClient.interceptors.request.use(
        async config => {
            const token = store.getters['auth/getToken'];
            if (token) {
                config.headers['Authorization'] = 'Bearer ' + token;
            }
            return config;
        },
        error => {
            return Promise.reject(error);
        },
    );

    apiClient.interceptors.response.use(
        response => {
            return response;
        },
        async error => {
            const originalRequest = error.config;
            if (error.response.status === 500) {
                error.response.data.message= ' Sistemsel bir hata oluştu. Lütfen daha sonra tekrar deneyiniz'
                throw error;
            }
            if (originalRequest.url !== "/auth/authenticate" && error.response) {
                if (error.response.status === 401 && !originalRequest._retry) {
                    store.dispatch('auth/logout');
                    router.push('/login');
                    //         originalRequest._retry = true;
                    //         try {
                    //             const newAccessToken = await store.dispatch('auth/refreshToken');
                    //             axios.defaults.headers.common['Authorization'] = 'Bearer ' + newAccessToken;

                    //             return apiClient(originalRequest);
                    //         } catch (err) {
                    //             store.dispatch('auth/logout');
                    //             console.log('Token is expired');
                    //             return Promise.reject(err);
                    //         }
                }
            }
            return Promise.reject(error);
        }
    );
    store.dispatch('updateLoading', false)
}
const performRequest = async (method, url, payload = null) => {
    try {
        store.dispatch('updateLoading', true);
        const response = await (payload ? apiClient[method](url, payload) : apiClient[method](url));
        return response.data;
    } catch (error) {
        console.log(error)
        throw error;
    } finally {
        store.dispatch('updateLoading', false);
    }
}
export { apiClient, setupInterceptors, performRequest };
