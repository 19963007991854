import { performRequest } from '@/services/axios.interceptor'

class BasketService {

    getMyBasket() {
        return performRequest('get', '/get-my-basket');
    }

    getBasketCount() {
        return performRequest('get', '/get-my-basket-count');
    }

    addBasketItem(basket) {
        return performRequest('post', '/add-basket-item', basket);
    }

    startPayment(data){
        return performRequest('post', '/buy-basket', data);
    }

    deleteBasketItem(plan) {
        return performRequest('post', '/delete-basket-item', plan);
    }
}

export default new BasketService();