<template>
    <div class="footer">
        <div class="footer-container">
            <div class="title" v-if="router.currentRoute.value.fullPath !== '/'">
                <a href="/">Anasayfa</a>
            </div>
            <div class="title" v-for="item in footerList" :key="item.title">
                <a :href="item.path">{{ item.title }}</a>
            </div>
        </div>
    </div>
</template>
<script setup>

import router from '@/router';

const footerList = [
    { title: 'Hakkımızda', path: '/' },
    { title: 'İletişim', path: '/contact-us' }
]

</script>
<style scoped>
.footer {
    display: flex;
    min-height: 50px;
    margin-left: -5px;
    margin-right: -5px;
    justify-content: center;
    background-color: var(--footer-color);
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    position: fixed;
}

.footer-container {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    width: 70%;
}

.title {
    text-align: center;
    padding: 30px
}

.footer a {
    color: white;
    text-decoration: none;
    font-size: 15px;
    font-weight: 600;
}

@media only screen and (max-width: 660px) {
    .footer a {
        font-size: 15px;
        font-weight: 300;
        margin: 5px;
    }

    .footer-container {
        flex-direction: column;
        align-items: center;
    }

    .title {
        padding: 8px 0px;
    }
}
</style>